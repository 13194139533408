import React, { Component } from "react";
import "./App.css";
import { Button, Input, Responsive, Segment } from "semantic-ui-react";

const style = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    fontFamily: "Roboto, sans-serif"
  },
  header: {
    margin: "24px 0",
    fontSize: "2em",
    lineHeight: "1.3em"
  },
  headerLabel: {
    fontSize: ".8em"
  },
  label: {
    fontSize: "12px",
    width: "80px",
    textAlign: "center"
  },
  button: {
    width: "80px"
  }
};

class App extends Component {
  state = {
    kg: 100,
    lb: 220.46,
    units: 0.7
  };

  render() {
    const unitsPerKg = (this.state.kg * this.state.units).toFixed(1);
    const unitsPerLb = (this.state.lb * this.state.units).toFixed(1);

    const morningDose = unitsPerKg * (2 / 3);
    const morningN = (morningDose * (2 / 3)).toFixed(1);
    const morningH = (morningDose * (1 / 3)).toFixed(1);

    const eveningDose = unitsPerKg * (1 / 3);
    const eveningN = (eveningDose * (1 / 2)).toFixed(1);
    const eveningH = (eveningDose * (1 / 2)).toFixed(1);
    return (
      <div style={style.container}>
        <Segment.Group>
          <Responsive as={Segment}>
            <div style={style.header}>
              <div style={style.headerLabel}>Insulin Dose</div>
              <div>
                <span style={style.headerLabel}>AM:</span> {morningN}N{" "}
                <span style={style.headerLabel}>/</span> {morningH}H
              </div>
              <div>
                <span style={style.headerLabel}>PM:</span> {eveningN}N{" "}
                <span style={style.headerLabel}>/</span> {eveningH}H
              </div>
            </div>
          </Responsive>
          <Responsive
            as={Segment}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Input
              label={{ basic: true, content: "kg", className: "inputLabel" }}
              labelPosition="right"
              placeholder="Enter weight..."
              value={this.state.kg}
              onChange={this.onKGChange}
              type="number"
              size="huge"
            />
            <Input
              label={{ basic: true, content: "lb", className: "inputLabel" }}
              labelPosition="right"
              placeholder="Enter weight..."
              value={this.state.lb}
              onChange={this.onLBChange}
              type="number"
              size="huge"
            />

            <Button.Group>
              <div>
                <Button
                  style={style.button}
                  active={this.state.units === 0.7}
                  onClick={() => this.onUnitsChange(0.7)}
                >
                  0.7
                </Button>
                <div style={style.label}>{"< 18 wks"}</div>
              </div>
              <div>
                <Button
                  style={style.button}
                  active={this.state.units === 0.8}
                  onClick={() => this.onUnitsChange(0.8)}
                >
                  0.8
                </Button>
                <div style={style.label}>{"18 - 26 wks"}</div>
              </div>
              <div>
                <Button
                  style={style.button}
                  active={this.state.units === 0.9}
                  onClick={() => this.onUnitsChange(0.9)}
                >
                  0.9
                </Button>
                <div style={style.label}>{"26 - 36 wks"}</div>
              </div>
              <div>
                <Button
                  style={style.button}
                  active={this.state.units === 1}
                  onClick={() => this.onUnitsChange(1)}
                >
                  1.0
                </Button>
                <div style={style.label}>{"> 36 wks"}</div>
              </div>
            </Button.Group>

            <div style={{ marginTop: "16px" }}>
              <div>Units/kg: {unitsPerKg}</div>
              <div>Units/lb: {unitsPerLb}</div>
            </div>
          </Responsive>
        </Segment.Group>
      </div>
    );
  }

  onKGChange = e =>
    this.setState({
      kg: e.target.value,
      lb: (e.target.value / 0.45359237).toFixed(1)
    });

  onLBChange = e =>
    this.setState({
      lb: e.target.value,
      kg: (e.target.value * 0.45359237).toFixed(1)
    });

  onUnitsChange = value => this.setState({ units: value });
}

export default App;
